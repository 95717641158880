import { Component } from 'solid-js'
import { TextFieldProps } from '~/components/fields/text/text.interfaces'
import styles from '~/components/fields/text/text.module.scss'

const TextField: Component<TextFieldProps> = ({ name, label, description, placeholder, suffix, guidelines, type = 'text', error, value, autocomplete, ...props }) => {
  const classList = () => ({
    [`${styles.container}`]: true,
    [`${styles.hasError}`]: Boolean(error),
  })

  return (
    <div classList={classList()}>
      {label && (
        <div class={styles.head}>
          <label
            for={name}
            class={styles.label}
          >
            {label}
          </label>
          {description && (
            <div class={styles.description}>
              {description}
            </div>
          )}
        </div>
      )}
      <div class={styles.inputContainer}>
        <input
          id={name}
          class={styles.input}
          placeholder={placeholder}
          type={type}
          autocomplete={autocomplete}
          value={value}
          {...props}
        />
        {suffix && (
          <div class={styles.suffix}>
            {suffix}
          </div>
        )}
      </div>
      {error && (
        <div class={styles.error}>
          {error}
        </div>
      )}
      {guidelines && (
        <div class={styles.guidelines}>
          {guidelines}
        </div>
      )}
    </div>
  )
}

export default TextField